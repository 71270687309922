export default {
    namespaced: true,
    state: {
        accountID: null,
        lastRegisterStep: null
    },
    getters: {
        HAS_COMPLETED_PROFILE: state => {
            return state.lastRegisterStep > 1
        }
    },
    mutations: {
        LOGIN_STATE(state, userData){
            let advertiser = userData.advertiser
            state.accountID = userData.id
            if ( advertiser ) {
                state.firstName = advertiser.firstName
                state.lastName = advertiser.lastName
            }
            state.email = userData.email
            state.phoneNumber = userData.phoneNumber
            state.avatar = userData.avatar

            state.lastRegisterStep = userData.lastRegisterStep

            localStorage.setItem('accountID', userData.id)
            if ( advertiser ) {
                localStorage.setItem('firstName', advertiser.firstName)
                localStorage.setItem('lastName', advertiser.lastName)
            }
            localStorage.setItem('email', userData.email)
            localStorage.setItem('avatar', userData.avatar)
            localStorage.setItem('phoneNumber', userData.phoneNumber)
            localStorage.setItem('lastRegisterStep', userData.lastRegisterStep)
        },
        UPDATE_LAST_PROFILE_STATE(state, last){
            state.lastRegisterStep = last

            localStorage.setItem('lastRegisterStep', last)
        },
        REFRESH_STORE(state) {
            state.accountID = parseInt(localStorage.getItem('accountID'))
            state.firstName = localStorage.getItem('firstName')
            state.lastName = localStorage.getItem('lastName')
            state.email = localStorage.getItem('email')
            state.avatar = localStorage.getItem('avatar')
            state.phoneNumber = parseInt(localStorage.getItem('phoneNumber'))
            state.lastRegisterStep = parseInt(localStorage.getItem('lastRegisterStep'))
        },
        UPDATE_PROFILE(state, advertiser) {
            console.log(advertiser)
            let userData = advertiser.account[0]
            state.accountID = userData.id
            state.firstName = advertiser.firstName
            state.lastName = advertiser.lastName
            state.email = userData.email
            state.phoneNumber = userData.phoneNumber
            state.avatar = userData.avatar


            state.lastRegisterStep = userData.lastRegisterStep

            localStorage.setItem('accountID', userData.id)
            localStorage.setItem('avatar', userData.avatar)
            localStorage.setItem('firstName', advertiser.firstName)
            localStorage.setItem('lastName', advertiser.lastName)
            localStorage.setItem('email', userData.email)
            localStorage.setItem('phoneNumber', userData.phoneNumber)
            localStorage.setItem('lastRegisterStep', userData.lastRegisterStep)
        }
    },
    actions: {
        LOGIN({ commit }, val) {
            commit('LOGIN_STATE', val)
        },
        UPDATE_LAST_PROFILE({commit}, val) {
            commit('UPDATE_LAST_PROFILE_STATE', val)
        },
        UPDATE_PROFILE({commit}, val) {
            console.log(val, "ini")
            commit('UPDATE_PROFILE', val)
        }
    },
}
