export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/advertisers/Dashboard'),
        meta: {
            pageTitle: 'Dashboard'
        },
    },
    {
        path: '/complete_account',
        name: 'complete-account',
        component: () => import('@/views/advertisers/CompletedProfile'),
        meta: {
            pageTitle: 'Lengkapi Profile Anda'
        },
    },
    {
        path: '/find_influencer',
        name: 'find-influencer',
        component: () => import('@/views/advertisers/Influencer/Index'),
        meta: {
            pageTitle: 'Influencer'
        },
    },
    {
        path: '/detail_influencer/:id',
        name: 'detail-influencer',
        component: () => import('@/views/advertisers/Influencer/Detail'),
        meta: {
            pageTitle: 'Detail Influencer'
        },
    },
    {
        path: '/detail_influencer/:id/review',
        name: 'review-influencer',
        component: () => import('@/views/advertisers/Influencer/Review'),
        meta: {
            pageTitle: 'Review Influencer'
        },
    },
    {
        path: '/campaign',
        name: 'campaign',
        component: () => import('@/views/advertisers/Campaign/Index'),
        meta: {
            pageTitle: 'Campaign'
        },
    },
    {
        path: '/campaign/create/step-1',
        name: 'campaign-create-1',
        component: () => import('@/views/advertisers/Campaign/Create/Step1'),
        meta: {
            pageTitle: 'Create Campaign',
            breadcrumb: [
                {
                    text: 'Campaign',
                    to: { name: 'campaign' }
                },
                {
                    text: 'Step 1',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/campaign/create/step-2-project',
        name: 'campaign-create-2-project',
        component: () => import('@/views/advertisers/Campaign/Create/Step2Project'),
        meta: {
            pageTitle: 'Create Campaign'
        },
    },
    {
        path: '/campaign/create/step-2-assistant',
        name: 'campaign-create-2-assistant',
        component: () => import('@/views/advertisers/Campaign/Create/Step2Assistant'),
        meta: {
            pageTitle: 'Create Campaign'
        },
    },
    {
        path: '/campaign/create/step-3',
        name: 'campaign-create-3',
        component: () => import('@/views/advertisers/Campaign/Create/Step3'),
        meta: {
            pageTitle: 'Create Campaign'
        },
    },
    {
        path: '/campaign/create/step-4',
        name: 'campaign-create-4',
        component: () => import('@/views/advertisers/Campaign/Create/Step4'),
        meta: {
            pageTitle: 'Create Campaign'
        },
    },
    {
        path: '/campaign/create/step-5',
        name: 'campaign-create-5',
        component: () => import('@/views/advertisers/Campaign/Create/Step5'),
        meta: {
            pageTitle: 'Create Campaign'
        },
    },
    {
        path: '/campaign/:id/detail',
        name: 'campaign-detail',
        component: () => import('@/views/advertisers/Campaign/Detail'),
        meta: {
            pageTitle: 'Detail Campaign'
        }
    },
    {
        path: '/campaign/edit/:id/step-1',
        name: 'campaign-edit-1',
        component: () => import('@/views/advertisers/Campaign/Create/Step1'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/edit/:id/step-2-assistant',
        name: 'campaign-edit-2-assistant',
        component: () => import('@/views/advertisers/Campaign/Create/Step2Assistant'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/edit/:id/step-2-project',
        name: 'campaign-edit-2-project',
        component: () => import('@/views/advertisers/Campaign/Create/Step2Project'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/edit/:id/step-3',
        name: 'campaign-edit-3',
        component: () => import('@/views/advertisers/Campaign/Create/Step3'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/edit/:id/step-4',
        name: 'campaign-edit-4',
        component: () => import('@/views/advertisers/Campaign/Create/Step4'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/edit/:id/step-5',
        name: 'campaign-edit-5',
        component: () => import('@/views/advertisers/Campaign/Create/Step5'),
        meta: {
            pageTitle: 'Edit Campaign'
        },
    },
    {
        path: '/campaign/:id/list_applicant',
        name: 'campaign-list-applicant',
        component: () => import('@/views/advertisers/Campaign/ListApplicant'),
        meta: {
            pageTitle: 'Data Influencer'
        },
    },
    {
        path: '/setting/user-information',
        name: 'user-information',
        component: () => import('@/views/advertisers/Setting/UserInformation'),
        meta: {
            pageTitle: 'Edit Informasi Pengguna'
        },
    },
    {
        path: '/setting/business-information',
        name: 'business-information',
        component: () => import('@/views/advertisers/Setting/BusinessInformation'),
        meta: {
            pageTitle: 'Edit Informasi Bisnis'
        },
    },
    {
        path: '/billing/pengajuan-termin',
        name: 'termin',
        component: () => import('@/views/advertisers/Billing/Termin'),
        meta: {
            pageTitle: 'Pengajuan Termin Pembayaran'
        },
    },
    {
        path: '/billing/create-termin',
        name: 'create-termin',
        component: () => import('@/views/advertisers/Billing/CreateTermin'),
        meta: {
            pageTitle: 'Tambah Pengajuan Termin Pembayaran'
        },
    },
    {
        path: '/billing/invoice-list',
        name: 'invoice',
        component: () => import('@/views/advertisers/Billing/InvoiceList'),
        meta: {
            pageTitle: 'Daftar Invoice'
        }
    },
    {
        path: '/billing/top-up-credit',
        name: 'top-up-credit',
        component: () => import('@/views/advertisers/Billing/Topup'),
        meta: {
            pageTitle: 'Top-Up Kredit Iklan'
        }
    },
    // Transaction History Route
    {
        path: '/billing/transaction-history',
        name: 'transaction-history',
        component: () => import('@/views/advertisers/Billing/TransactionHistory'),
        meta: {
            pageTitle: 'Riwayat Transaksi'
        }
    }
]